.row.block-img-x3{
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: space-evenly;
  margin: 0;
}
.row.block-img-x2{
  display: grid;
  grid-template-columns: repeat(auto-fill, 170px);
  justify-content: space-evenly;
  grid-column-gap: 10px;
  padding: 0;
}