@import "styles/variables.scss";

.MuiExpansionPanel-root.collapsible-filter {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-top: 1px solid $lightgrey;
  margin: 0 !important;
  &:before {
    display: none;
  }
  &:last-of-type {
    border-bottom: 1px solid $lightgrey;
  }
  .MuiExpansionPanelDetails-root {
    flex-direction: column;
    padding: 0 0 10px 0;
    .check-all-items {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $primary;
      margin-top: 10px;
      margin-bottom: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: right;
        color: $darkgrey;
      }
      .datepicker-main {
        margin: 5px 0;
      }
    }
  }
  .MuiButtonBase-root {
    padding: 0;
    min-height: inherit;
    .MuiExpansionPanelSummary-content {
      line-height: 1;
      margin: 0;
      padding: 10px 0;
      position: relative;
      z-index: 10;
      &.Mui-expanded {
        &:before,
        &:after {
          transform: rotate(0deg) !important;
        }
      }
      &:hover {
        cursor: pointer;
      }
      &:before,
      &:after {
        $width: 16px;
        $height: 2px;
        z-index: -1;
        position: absolute;
        transform: rotate(-0deg);
        content: "";
        top: 18px;
        right: 10px;
        margin-top: -$height/2;
        margin-left: -$width/2;
        display: block;
        height: $height;
        width: $width;
        background-color: $black;
        transition: all 0.15s ease-out;
      }

      &:after {
        transform: rotate(-90deg);
      }
      p {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $black;
      }
    }
  }
}
