@import "styles/variables.scss";

.avatar-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  > div {
    position: relative;
    z-index: 10;
    background-color: #ffffff;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    &:not(:first-of-type) {
      margin-left: -30px;
    }
  }
}
