@import "styles/variables.scss";

.note-block {
  margin-top: 40px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    span {
      font-size: 24px;
      font-weight: 500;
      line-height: 1;
      color: $black;
    }
    button {
      background: none;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: $primary;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .notes-lists {
    padding: 0;
    margin: 0;
    > li {
      border-radius: 8px;
      border: solid 1px $lightgrey;
      background-color: $white;
      margin-bottom: 15px;
      padding: 15px 10px 15px 15px;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      .text-box {
        font-size: 16px;
        color: $darkgrey;
        display: flex;
        align-items: center;
      }
      .lists__header {
        display: flex;
        justify-content: space-between;
        height: 30px;
        align-items: center;
        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 1;
          color: $black;
        }
        div {
          display: flex;
          align-items: center;
          .date {
            margin-right: 5px;
            font-size: 12px;
            line-height: 1;
            color: $darkgrey;
          }
        }
      }
    }
  }
  .send-note {
    margin: 50px 0;
  }
}
.send-note {
  display: block;
  margin: 0;
  position: relative;
  height: 65px;
  border-radius: 4px;
  border: solid 1px $lightgrey;
  background-color: $white;
  padding-right: 70px;
  input {
    height: 100%;
    width: 100%;
    display: block;
    margin: 0;
    padding: 0 25px;
    line-height: 70px;
    color: $darkgrey;
  }
  label {
    height: 100%;
    width: 100%;
    display: block;
    margin: 0;
  }
  button {
    background: $primary !important;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 70px;
    border-radius: 4px;
  }
}
