@import "styles/variables.scss";

.simpleDropdown {
  color:black;
  position: relative;
  &-header {
    &:hover {
      cursor: pointer;
    }
  }
  &-dropdown {
    &:before {
      content: "";
      position: absolute;
      top: -8px;
      right: 104px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent #ffffff transparent;
    }
    position: absolute;
    top: 45px;
    right: -100px;
    z-index: 1000;
    background: $white;
    padding: 30px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: wrap;
    min-width: 650px;
    > div {
      width: 50%;
    }
  }
}
