@import "styles/variables.scss";

.select_block {
  &.btn-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > div {
      margin-right: 30px;
      button:first-of-type {
        margin-right: 10px;
      }
    }
  }
}
