$white: #ffffff;

$black: #546369;

$lightgrey: #ebf0f4; //ice-blue in Zeplin
$grey: #cbdae0;
$darkgrey: #809096;

$primary: #445bf4;
$lightish-blue: #445BF4; // is part of the Zeplin design documents as "lightish-blue
$blue-grey: #A1ADB1; // is part of the Zeplin design documents as "blue-grey
$primary-dark: #3d51db;

$primary-light: #f8faff;
$primary-lighter: darken($primary-light, 5%);
$primary-transparent: #eceffe;
$primary-transparent-rgba: rgba(68, 91, 244, 0.2);
$red: #f56b6b;
$green: #4cad3a;

//Status Color
$status-color-1: #f5af6b;
$status-color-2: #e9df6d;
$status-color-3: #6db8e9;
$status-color-4: #d16de9;

// For JS
:export {
  white: $white;
  black: $black;
  lightgrey: $lightgrey;
  grey: $grey;
  darkgrey: $darkgrey;
  primary: $primary;
  primaryDark: $primary-dark;
  primaryLighter: $primary-lighter;
  primaryLight: $primary-light;
  primaryTransparent: $primary-transparent;
  red: $red;
  green: $green;
  statusColor1: $status-color-1;
  statusColor2: $status-color-2;
  statusColor3: $status-color-3;
  statusColor4: $status-color-4;
}
