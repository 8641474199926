@import "styles/variables.scss";

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 33.33%;
    display: flex;
    align-items: center;
    &:first-of-type {
      justify-content: flex-start;
    }
    &:nth-child(2) {
      justify-content: center;
    }
    &:nth-child(3) {
      justify-content: flex-end;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-modules {
      margin: 0 30px;
    }
  }
}
