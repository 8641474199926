@import "styles/variables.scss";

.tab-bar-box {
  a,
  button {
    display: inline-block;
    padding: 10px 20px;
    background: none;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $darkgrey;
    border-radius: 8px;
    &:disabled {
      &:hover {
        color: $darkgrey;
        text-decoration: none;
      }
      opacity: 0.5;
    }
    &:hover {
      color: $black;
      text-decoration: none;
    }
    &.active {
      color: $primary;
      background: $primary-transparent;
    }
  }
}
