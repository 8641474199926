@import "styles/variables.scss";

.addItem {
  color:$black;
  position: relative;
  &-header {
    &:hover {
      cursor: pointer;
    }
  }
  &-dropdown {
    &:before {
      content: "";
      position: absolute;
      top: -8px;
      right: 134px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent #ffffff transparent;
    }
    position: absolute;
    top: 45px;
    //right: -100px;
    z-index: 1000;
    background: $white;
    //padding: 24px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: wrap;
    width: 260px;

    .container {
      padding: 24px;
    }

    .col {
      padding: 0;
    }

    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .icon-wrapper-circle {
    width: 32px;
    height: 32px;
    background-color: $primary;
    border: 0;
  }

  .addItem__trigger {
    display: inline-flex;
    width: 150px;
    margin-left: 10px;
    justify-content: center;
    align-items: center;

    div {
      margin: 0 5px 0 5px;
    }
  }

  .addItem__sectionName {
    display: inline-block;
    margin-bottom: 28px;
    font-size: 12px;
    color: $blue-grey;
  }

  .addItem__text {
    display: inline-block;
  }

  .addItem__link {
    vertical-align: middle;
    height: 24px;
    display: inline-block;
  }

  .addItem__text {
    color: $lightish-blue;
    margin-left: 16px;
    font-weight: 500;
  }

  .spacer {
    margin: 15px 0 15px 0;
  }

  .addItem__carrot{
    width:64px;
    //height:24px;
    display: inline-block;
  }
  &-open {
    .addItem__carrot {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
}
