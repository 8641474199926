@import "styles/variables.scss";

.card-item__profile {
  .card-item-body {
    .img-box {
      justify-content: center;
      border-bottom: 1px solid $lightgrey;
    }
  }
  .card-item-body .description > div.detail > div {
    max-width: inherit;
    width: 100%;
    justify-content: space-between;
    div {
      margin: 0;
    }
  }
}
