@import "styles/variables.scss";

.external-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  a {
    display: flex;
    line-height: 1.2;
    color: $primary;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    max-width: 160px;
    white-space: nowrap;
  }
  .label {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $black;
    margin-left: 10px;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    max-width: 160px;
    white-space: nowrap;
    &.clickable {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
