@import "styles/variables.scss";

.card-item {
  height: 515px;
  border-radius: 8px;
  border: solid 1px $lightgrey;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;

  &:hover {
    .card-item-footer {
      background-color: $primary-transparent;
      color: $primary;

      a {
        visibility: visible;
        opacity: 1;
      }

      button {
        color: $primary;
      }
    }
  }

  svg {
    display: block;
  }

  &-header {
    flex-shrink: 0;
    padding: 8px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .img-header-card {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    .label-checkbox-default {
      margin: 0;

      i {
        margin: 0;
      }
    }

    .title {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 16px;
      letter-spacing: normal;
      color: $darkgrey;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .children-box {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 10;
    }

    .img-box {
      height: 216px;
      position: relative;
      display: flex;
      overflow: hidden;

      .card-slider {
        height: 100%;
        width: 100%;

        .view-panel-slider {
          height: 100%;

          .slick-prev, .slick-next {
            background: white;
            width: 24px;
            height: 24px;
            z-index: 10;
            border: 1px solid $grey;
            border-radius: 3px;

            &:before {
              display: none;
            }

            svg {
              width: 24px;
              height: 24px;
            }

            &:hover {
              cursor: pointer;
            }
          }

          .slick-prev {
            left: 25px;
          }

          .slick-next {
            right: 25px;
          }
        }
      }

      .card-item-body-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 25px 15px 25px;
      }

      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
      }
    }

    .description {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px 25px;

      .title-description-card {
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: $black;
        padding-bottom: 10px;
        line-height: 1.33;

        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          display: block;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: $black;
        }
      }

      > div {
        &.detail {
          display: flex;
          justify-content: space-between;
          padding: 15px 0 0 0;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: right;
          color: $darkgrey;

          span {
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $black;
            margin-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > div {
            display: flex;
            justify-content: space-between;
            max-width: 50%;

            &.views-card {
              div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &.views-card div:nth-child(2) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  &-footer {
    flex-shrink: 0;
    border-top: solid 1px $lightgrey;
    padding: 0 25px;
    min-height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $black;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;

    > div {
      width: 15%;
      display: flex;
      justify-content: flex-start;

      &:last-of-type {
        justify-content: flex-end;
      }
    }

    button {
      background: none;
      color: $black;
    }

    a {
      visibility: hidden;
      opacity: 0;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
  }
}