@import "styles/variables.scss";

.text-box-card {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  &.show {
    .all-text {
      visibility: visible;
      opacity: 1;
    }
  }
  .show-text {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: $darkgrey;
    &:hover {
      cursor: pointer;
    }
  }
  a {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $darkgrey;
    text-decoration: none;
  }
  .text {
    height: 50px;
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: $black;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
  .all-text {
    padding: 5px;
    box-sizing: content-box;
    display: block;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    top: -5px;
    left: -5px;
    width: 100%;
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: $black;
    background: $white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
  }
}
