@import "styles/variables.scss";

.label-radio-input-default {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    display: block;
  }
  &:hover {
    cursor: pointer;
  }
  input[type="radio"] {
    display: none;
  }
  input + span {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -8px;
      width: 16px;
      height: 16px;
      border: solid 2px $darkgrey;
      background-color: $white;
      border-radius: 50%;
    }
    &.checked {
      &:before {
        border-color: $primary;
      }
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -4px;
        width: 8px;
        height: 8px;
        background-color: $primary;
        border-radius: 50%;
      }
    }
  }
  .title {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #546369;
  }
}
