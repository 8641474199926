@import "styles/variables.scss";

.out-lined-chips{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 8px;
  .MuiChip-root{
    background: $white;
    .MuiChip-label{
      display: inline-block;
      max-width: 140px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      color: $black;
    }
  }
  .MuiChip-root, .clear-all-btn{
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .clear-all-btn{
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $primary;
    background: none;
  }
}
