@import "styles/variables.scss";

.options-top-bar {
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;
  background: $white;
  width: 100%;
  border-bottom: 1px solid $lightgrey;
  margin-top: -100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &.show {
    margin-top: 0;
    opacity: 1;
  }
  &__list {
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 400px;
    display: flex;
    > div {
      width: 100%;
      max-height: 100%;
      overflow-x: hidden;
    }
    .table-main {
      border: none;
    }
  }
  &__options {
    padding: 20px 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .list-actions{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .select_block,
    .config_block ul {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }
    .select-all {
      background: none;
      color: $black;
      margin-right: 15px;
    }
  }
}
.btn-bar__list {
  background: none;
  margin-right: 15px;
  &.show {
    svg {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  svg {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
.bg-list-top-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: $black;
  opacity: 0.3;
  z-index: 100;
  &:hover {
    cursor: pointer;
  }
}
