@import "styles/variables.scss";

.label-checkbox-default {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    display: block;
  }
  svg {
    &:hover {
      use {
        fill: $primary;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
  input[type="checkbox"] {
    display: none;
  }
  input + span {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
  .title {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 25px;
    letter-spacing: normal;
    color: #546369;
  }
}
