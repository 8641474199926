@import "styles/variables.scss";

.add-item-inner {
  padding: 50px 0;
  position: relative;

  .back-link:hover{
      cursor:pointer;
  }

  .back-link {
    position: absolute;
    top: 50px;
    left: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $black;
    text-decoration: none;
  }
  &__form {
    margin: 0 auto;
    background: $white;
    width: 100%;
    max-width: 825px;
    border-radius: 8px;
    border: solid 1px $lightgrey;
    padding: 40px 100px;
    .btn-list-primary {
      margin-bottom: 40px;
    }
    .add-more-box {
      display: none;
      &.show {
        display: block;
      }
    }
    .form-dropdown {
      margin-bottom: 15px;
    }
    .field-title,
    .label-default .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: $black;
      margin-bottom: 10px;
    }
    .radio-group {
      margin-bottom: 25px;
    }
    .tab-bar-box {
      margin-bottom: 40px;
    }
    .row-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      color: $black;
      margin-bottom: 25px;
    }
    .fields-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > label {
        width: 24%;
      }
      &.double {
        > label {
          width: 49%;
        }
      }
    }
    .btn-row {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      button {
        margin-right: 20px;
      }
    }
  }
}
.form-title {
  margin-bottom: 25px;
  font-size: 34px;
  line-height: 1;
  letter-spacing: normal;
  color: $black;
}

.radio-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  label {
    margin-right: 20px;
  }
}
