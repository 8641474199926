@import "styles/variables.scss";

.profile-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  li {
    font-weight: 500;
    line-height: 1;
    color: #546369;
    margin-bottom: 10px;
    span {
      color: $darkgrey;
      margin-right: 5px;
    }
  }
}