@import "styles/variables.scss";

// There will be multiple different grid views
.landingContainer-defaultGridView {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1em;
    margin-top: 1em;

    @media (min-width: 1400px) {
        grid-template-columns: repeat(4, minmax(300px, 1fr));
    }
}

/*
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }
*/