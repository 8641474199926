@import "styles/variables.scss";

.view-panel-mixed-wrapper{
  .info-wrapper{
    .statistic{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 35px;
      >div{
        margin-right: 25px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: $darkgrey;
        span{
          margin-left: 5px;
          color: $black;
        }
      }
    }
  }
  .tab-bar-box{
    margin-bottom: 20px;
  }
  .view-panel-slider{
    height: 330px;
  }
  .list-info{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }
  .head-panel{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .slider-side{
      display: flex;
      justify-content: space-between;
      .slider-nav{
        width: 70px;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
        max-height: 330px;
        .image-length{
          border: 1px solid $lightgrey;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          text-align: center;
          color: $black;
          &:hover{
            background: $lightgrey;
          }
        }
        >div{
          margin-bottom: 7px;
          height: 60px;
          min-height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid transparent;
          overflow: hidden;
          border-radius: 7px;
          &:hover{
            cursor: pointer;
          }
          &.active{
            border: 1px solid $lightgrey;
          }
          img{
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
      .view-panel-slider{
        width: calc( 100% - 85px );
      }
    }
    .description-side{
      padding-left: 25px;
      .title-post{
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        color: $black;
        margin: 30px 0;
      }
      .price-date{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        .price{
          font-size: 32px;
          font-weight: bold;
          color: $green;
        }
        .date{
          font-size: 12px;
          font-weight: normal;
          color: $darkgrey;
        }
      }
    }
    >div{
      width: 50%;
    }
  }
}