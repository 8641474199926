@import "styles/variables.scss";

.status-dropdown {
  display: inline-flex;
  .wrapper-button.show {
    button {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    }
  }
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 205px;
    text-align: center;
    padding: 10px 15px 10px 40px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $black;
    border-radius: 20px;
    border: solid 1px $lightgrey;
    background-color: #ffffff;
  }
  .dropdown-list {
    border-radius: 8px;
    top: 115%;
  }
  .dropdown-list ul {
    padding: 10px;
    li {
      padding: 10px 10px 10px 30px;
      border-radius: 8px;
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $darkgrey;
      &:hover {
        background: $primary-transparent;
        color: $primary;
      }
    }
  }
  .list-status,
  .btn-status {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 18px;
      top: 50%;
      margin-top: -4px;
      display: block;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      border: solid 2px $red;
      background-color: $white;
    }
    &.suspicious,
    &.unauthorized {
      &:before {
        border-color: $status-color-1;
      }
    }
    &.unhanded,
    &.no-label {
      &:before {
        border-color: $grey;
      }
    }
    &.genuine,
    &.authorized {
      &:before {
        border-color: $green;
      }
    }
    &.infringement {
      &:before {
        border-color: $red;
      }
    }
    &.irrelevant,
    &.unable-to-determine {
      &:before {
        border-color: $black;
      }
    }
  }
  .list-status {
    &:before {
      left: 10px;
    }
  }
}
