@import "styles/variables.scss";

.view-details-panel {
  overflow-y: auto;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  height: 100vh;
  width: 450px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  background-color: $white;
  padding: 15px 25px;
  .text-box-card {
    margin-bottom: 20px;
  }
  .info-wrapper {
    margin-top: 25px;
  }
  .nav-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      margin-right: 15px;
      &:last-of-type {
        margin-right: 0;
      }
      &.hidden {
        opacity: 0.5;
        &:hover {
          cursor: default;
        }
      }
    }
    .close-btn {
      margin-right: 25px;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    button {
      background: none;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .title {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 16px;
      letter-spacing: normal;
      color: $darkgrey;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 5px;
    }
    .title-description-card {
      > div {
        font-size: 24px;
        font-weight: 500;
        line-height: 1;
        color: $black;
        margin-bottom: 5px;
      }
      > span {
        font-size: 12px;
        line-height: 1;
        color: $darkgrey;
      }
    }
    .img-logo {
      img {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
  &__body {
    .tab-bar-box {
      button {
        padding: 10px;
      }
    }
  }
}
.tab-panel {
  .show-more {
    display: inline-block;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $darkgrey;
    background: none;
    &:hover {
      cursor: pointer;
    }
  }
}
.activity-history {
  margin: 25px 0 0 0;
  li {
    margin-bottom: 15px;
    .date {
      height: 20px;
      line-height: 20px;
      padding: 0 15px;
      border-radius: 10px;
      border: solid 1px $lightgrey;
      font-size: 12px;
      text-align: center;
      color: $black;
      display: inline-block;
    }
    .content-box {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      .logo-img {
        width: 30px;
        height: 30px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
      > div {
        &:first-of-type {
          padding-left: 35px;
        }
        &:nth-child(2) {
          padding-left: 10px;
          span {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            color: $black;
          }
          p {
            font-size: 16px;
            line-height: 1.5;
            color: $darkgrey;
          }
        }
      }
    }
  }
}
.view-panel-wrapper {
  .view-details-panel__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .profile-box {
      display: flex;
      width: 100%;
      margin-bottom: 15px;
      .img-logo {
        margin-right: 15px;
      }
    }
    .title {
      margin-bottom: 20px;
    }
    .date {
      font-size: 12px;
      line-height: 1;
      color: $darkgrey;
      margin: 25px 0;
    }
    .name {
      font-size: 24px;
      font-weight: 500;
      line-height: 1;
      color: $black;
      margin-bottom: 5px;
    }
    .dropdown.status-dropdown,
    .dropdown-body,
    .dropdown-body .wrapper-button > button {
      width: 100%;
    }
  }
  .image-block {
    height: 180px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    img {
      max-width: 100%;
      height: 100%;
    }
  }
}
