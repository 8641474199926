@import "styles/variables.scss";

.set-infringing-status-type {
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin-left: 10px;
    &:first-of-type {
      margin: 0;
    }
  }
}

