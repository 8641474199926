@import "styles/variables.scss";

.main-modal {
  padding: 40px !important;
  background: $white;
  width: 100%;
  max-width: 1250px;
  height: auto;
  max-height: 800px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  .btn-row {
    button {
      margin-left: 15px;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
  &.import {
    height: auto;
    width: 100%;
    max-width: 920px;
    .svg {
      margin-bottom: 20px;
      text-align: center;
    }
  }
  &.export {
    width: 600px;
    height: auto;
  }
  .close-modal {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
  }
  .title-slider {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $black;
    margin-bottom: 25px;
  }
  .footer-import {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}