@import "styles/variables.scss";

.label-default {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  position: relative;
  .wrapper-input {
    border-radius: 4px;
    border: solid 1px $lightgrey;
    background-color: $white;
    padding: 10px 15px;
    > div {
      display: flex;
      align-items: center;
    }
    &:focus {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    }
  }
  ::-webkit-input-placeholder {
    color: $darkgrey;
  }
  ::-moz-placeholder {
    color: $darkgrey;
  }
  :-ms-input-placeholder {
    color: $darkgrey;
  }
  :-moz-placeholder {
    color: $darkgrey;
  }
  &.with-children {
    .wrapper-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      width: 100%;
    }
    input {
      flex-grow: 1;
    }
  }
  &.error {
    ::-webkit-input-placeholder {
      color: $red;
    }
    ::-moz-placeholder {
      color: $red;
    }
    :-ms-input-placeholder {
      color: $red;
    }
    :-moz-placeholder {
      color: $red;
    }
    .wrapper-input {
      border-color: $red;
      color: $red;
    }
    .error-message {
      color: $red;
      font-size: 10px;
      position: absolute;
      bottom: -15px;
      left: 0;
    }
  }
  &.success {
    ::-webkit-input-placeholder {
      color: $green;
    }
    ::-moz-placeholder {
      color: $green;
    }
    :-ms-input-placeholder {
      color: $green;
    }
    :-moz-placeholder {
      color: $green;
    }
    .wrapper-input {
      border-color: $green;
      color: $green;
    }
    .error-message {
      color: $green;
      font-size: 10px;
      position: absolute;
      bottom: -15px;
      left: 0;
    }
  }
  .title {
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $black;
    margin-bottom: 5px;
  }
  input {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $darkgrey;
    width: 100%;
  }
}
