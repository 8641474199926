@import "styles/variables.scss";


//@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,500,700,900");
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

* {
  font-family: "Roboto", sans-serif;
}

.hero {
  font-size: 96px;
  font-weight: bold;
  line-height: 1.17;
  color: $black;
}

h1 {
  font-size: 60px;
  font-weight: bold;
  line-height: 1.2;
  color: $black;
}

h2 {
  font-size: 48px;
  font-weight: 500;
  line-height: 1.25;
  color: $black;
}

h3 {
  font-size: 34px;
  font-weight: normal;
  line-height: 1.18;
  color: $black;
}

h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: $black;
}

h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: $black;
}

.subtitle-1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: $black;
}

.subtitle-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $black;
}

body,
.body-1 {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: $black;
}

.body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $black;
}

.caption {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  color: $black;
}
