@import "styles/variables.scss";

.tooltip-box {
  position: relative;
  display: inline-flex;
  line-height: 1;
  white-space: normal;
  a {
    line-height: 1;
  }
  &:hover {
    cursor: pointer;
    .tooltip-body {
      opacity: 1;
      visibility: visible;
      margin-top: 0;
    }
  }
  .tooltip-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: -5px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    max-width: 180px;
    min-width: 80px;
    max-height: 53px;
    background-color: $black;
    padding: 10px 15px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-130%);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    z-index: 10;
    box-sizing: content-box;
    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9px 7px 0 7px;
      border-color: $black transparent transparent transparent;
      bottom: -7px;
      left: 50%;
      margin-left: -7px;
    }
  }
}
