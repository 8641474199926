@import "styles/variables.scss";

.card-header-actions {
  &-body {
    position: relative;
    z-index: auto;
    display: inline-flex;
    overflow: hidden;
    svg {
      &:hover {
        g > g:last-of-type {
          fill: $primary;
        }
      }
    }
    &.show {
      overflow: inherit;
      .card-header-actions-drop-down {
        opacity: 1;
        visibility: visible;
        margin-top: 0;
      }
    }
    &:hover {
      cursor: pointer;
    }
    &.top {
      .card-header-actions-drop-down {
        top: inherit;
        bottom: 140%;
      }
    }
    &.bottom {
      .card-header-actions-drop-down {
        top: 140%;
        bottom: inherit;
      }
    }
  }
  &-drop-down {
    position: absolute;
    z-index: 15;
    display: block;
    visibility: hidden;
    opacity: 0;
    width: 260px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 5px 0;
    right: -2px;
    top: 140%;
    margin-top: -20px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: $white;
    ul {
      margin: 0;
      width: 100%;
      li {
        a,
        button {
          width: 100%;
          background: none;
          padding: 15px 25px;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: normal;
          color: $darkgrey !important;
          display: flex;
          align-items: center;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          &:hover {
            text-decoration: none !important;
            cursor: pointer;
            background: $primary-transparent;
          }
          svg {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
