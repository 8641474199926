@import "./Reset.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "styles/Typography/typography.scss";
@import "styles/variables.scss";

* {
  box-sizing: border-box;
  outline: none !important;
}
*:focus {
  outline: none !important;
}

body {
  background: inherit !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//Modules style start ----------------------------------------
.page-container {
  width: 100%;
  max-width: 1830px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  &__full-width {
    max-width: inherit;
  }
}
.page {
  padding-left: 65px;
  &.with-open-side-bar {
    padding-left: 325px;
  }
}
.bg-primary-transparent {
  background: $primary-light;
  min-height: 100vh;
}
//Modules style end    ----------------------------------------

//Page header start  ----------------------------------------
.page-header {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $lightgrey;
  .title {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $black;
  }
}
//Page header end ----------------------------------------------

//SideBar style start ------------------------------------------
.side-bar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: $white;
}
//SideBar style end  -------------------------------------------

// BTN box start ------------------------------------------------
.btn {
  height: 40px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: $black;
  svg {
    margin-right: 5px;
  }
  &:hover {
    color: $black;
  }
  &-blue {
    background-color: $primary;
    color: $white;
    &:hover {
      color: $white;
    }
  }
}

.btn-list-primary {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: $primary;
  background: none;
}
.error-message {
  color: $red;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

//BTN box end ---------------------------------------------------

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//custom scroll
.scroll-m {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $primary;
  }
}

.date-price{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .date{
    font-size: 12px;
    line-height: 1;
    color: $black;
  }
  .price{
    font-size: 20px;
    line-height: 1;
    color: $black;
  }
}

.item-name{
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  color: #546369;
  margin-bottom: 10px;
}