@import "styles/variables.scss";

.page-footer {
  padding: 15px 0 30px 0;
  display: flex;
  > div {
    width: 33.33%;
    display: flex;
    align-items: center;
    &:first-of-type {
      justify-content: flex-start;
    }
    &:nth-child(2) {
      justify-content: center;
    }
    &:nth-child(3) {
      justify-content: flex-end;
    }
  }
  &-protect {
    > div:nth-child(3) {
      display: inline-flex;
      align-items: center;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.pagination-list {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  .previous,
  .next {
    display: none;
  }
  li {
    margin-right: 10px;
    a {
      min-width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: $darkgrey;
      text-decoration: none;
      padding: 0px 10px;
      &:hover {
        background: $primary-transparent;
        cursor: pointer;
      }
    }
    &.active {
      a {
        color: $primary;
        background: $primary-transparent;
      }
    }
  }
}
.load-more {
  &:hover {
    cursor: pointer;
  }
  svg {
    margin-right: 15px;
  }
  &.loading {
    opacity: 0.8;
    svg {
      -webkit-animation: rotating 1s linear infinite;
      -moz-animation: rotating 1s linear infinite;
      -ms-animation: rotating 1s linear infinite;
      -o-animation: rotating 1s linear infinite;
      animation: rotating 1s linear infinite;
    }
  }
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: $darkgrey;
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
.pagination-counter {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: $darkgrey;
  margin-right: 25px;
  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: $black;
    margin-right: 5px;
  }
}
.pagination-buttons {
  display: inline-flex;
  align-items: center;
  margin: 0;
  li {
    margin-left: 15px;
    &:first-of-type {
      margin: 0;
    }
    a,
    button {
      &:disabled {
        opacity: 0.5;
        &:hover {
          background: none;
        }
      }
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: none;
      border: 1px solid $lightgrey;
      &:hover {
        background: $primary-transparent;
      }
    }
  }
}
