@import "styles/variables.scss";

.btn-svg-hover {
  position: relative;
  height: 35px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  border: 1px solid #edf7eb;
  color: #4cad3a;
  background: #edf7eb;
  padding: 0 35px 0 15px;
  &:hover {
    color: $primary;
    background-color: $primary-transparent;
    svg {
      &:first-of-type {
        display: none;
      }
      &:last-of-type {
        display: block;
      }
    }
  }
  svg {
    position: absolute;
    right: 10px;
    top: 8px;
    &:last-of-type {
      display: none;
    }
  }
}
