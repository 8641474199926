@import "styles/variables.scss";
@import "../../../../node_modules/slick-carousel/slick/slick.css";
@import "../../../../node_modules/slick-carousel/slick/slick-theme.css";

.view-panel-slider {
  height: 558px;
  .slick-list,
  .slick-track {
    height: 100%;
  }
  .slick-slide {
    > div {
      height: 100%;
    }
    > div > div {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
.view-panel-slider-list {
  height: 90px;
  margin-top: 25px;
  .slick-list,
  .slick-track {
    height: 100%;
    .slick-slide {
      > div > div {
        border-radius: 8px;
        overflow: hidden;
        width: 89px !important;
        border: solid 2px transparent;
        &:hover {
          cursor: pointer;
        }
        img {
          width: 89px;
          height: 85px;
        }
      }
      &.slick-current {
        > div > div {
          position: relative;
          border: solid 2px $primary;
          &:before {
            content: "";
            position: absolute;
            background: $primary;
            opacity: 0.5;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
          }
        }
      }
    }
    .slick-current {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }
}
