@import "styles/variables.scss";

.btn-type {
  &-default {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
  }
  &-circle {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.btn-size-large {
      width: 50px;
      height: 50px;
    }
    &.btn-size-small {
      width: 32px;
      height: 32px;
    }
  }
}

.btn-color {
  &-secondary {
    background-color: $white;
    color: $black;
    border: 1px solid $primary-transparent;
    &:focus,
    &:hover {
      background: $primary-transparent;
    }
    &:active {
      opacity: 0.9;
    }
    &:disabled {
      background-color: $grey !important;
      border-color: $grey !important;
      color: $black !important;
      &:hover {
        cursor: auto !important;
      }
    }
  }
  &-primary {
    background-color: $primary;
    color: $lightgrey;
    border: 1px solid $primary;
    &:focus,
    &:hover {
      background: $primary-dark;
    }
    &:active {
      opacity: 0.9;
    }
    &:disabled {
      background-color: $grey !important;
      border-color: $grey !important;
      color: $black !important;
      &:hover {
        cursor: auto !important;
      }
    }
  }
}

.btn-size {
  &-large {
    width: 160px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
  &-small {
    width: 130px;
    height: 40px;
    text-align: center;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-size: 14px;
    line-height: 40px;
  }
}
