@import "styles/variables.scss";

.table-main {
  border-radius: 8px;
  border: solid 1px $lightgrey;
  background-color: $white;
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
  }
  &.large {
    tbody tr td {
      padding: 15px 10px;
    }
  }
  &.medium {
    tbody tr td {
      padding: 10px 10px;
      img.img-profile {
        width: 40px;
        height: 40px;
      }
    }
  }
  &.small {
    tbody tr td {
      padding: 5px 10px;
      img.img-profile {
        width: 30px;
        height: 30px;
      }
    }
  }
  width: 100%;
  tr {
    border-bottom: 1px solid $lightgrey;
  }
  .label-checkbox-default {
    margin: 0;
    i {
      margin: 0;
    }
  }
  thead {
    tr {
      th {
        padding: 15px 10px;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $darkgrey;
        vertical-align: middle;
        .filter {
          position: relative;
          padding: 10px;
          white-space: nowrap;
          &.asc {
            &:after {
              display: none;
            }
          }
          &.desc {
            &:before {
              display: none;
            }
          }
          &:before {
            content: "";
            position: absolute;
            right: 0px;
            top: 50%;
            margin-top: -5px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 3px 5px 3px;
            border-color: transparent transparent $darkgrey transparent;
          }
          &:after {
            content: "";
            position: absolute;
            right: 0px;
            top: 50%;
            margin-top: 1px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 3px 0 3px;
            border-color: $darkgrey transparent transparent transparent;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  tbody {
    tr {
      &:last-of-type {
        border: none;
      }
      td {
        padding: 15px 10px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $black;
        vertical-align: middle;
        white-space: nowrap;
        width: 1%;
        a {
          color: $primary;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        img.img-profile {
          width: 50px;
          height: 50px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          margin-right: 15px;
          &:hover {
            cursor: pointer;
          }
        }
        .remove-card {
          background: none;
        }
      }
    }
  }
}
.flex-group-center {
  display: flex;
  align-items: center;
}
