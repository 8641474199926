@import "styles/variables.scss";

.side-bar {
  z-index: 100;
  &.open {
    width: 325px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .side-bar-list-icon{
      .filters > div{
        background: $primary-transparent;
        svg g{
          >g,>use,>mask{
            fill: $primary;
          }
        }
      }
    }
  }
  .dropdown.search .dropdown-list.bottom{
    padding-top: 46px;
  }
  .dropdown.search .dropdown-list.top{
    padding-bottom: 46px;
  }
  .side-bar-left{
    width: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 30px 15px;
    border-right: 1px solid $lightgrey;
    height: 100%;
  }
  .side-bar-right {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 30px 15px;
    .dropdown-list.bottom {
      padding-top: 45px;
    }
    .dropdown-body{
      width: 100%;
      .dropdown-list{
        border-left: 1px solid $lightgrey;
        border-right: 1px solid $lightgrey;
      }
      .wrapper-input{
        border-radius: 0;
        border-left: none;
        border-right: none;
      }
      .search-row{
        padding: 0;
      }
    }
    .logo-btn-box {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
      span{
        font-size: 20px;
        line-height: 1.4;
        font-weight: 500;
      }
      .side-bar-hide {
        &:hover {
          cursor: pointer;
        }
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
    .button-panel {
      margin-top: 10px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      .btn {
        width: 50%;
        max-width: 120px;
      }
    }
    .top-panel {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: calc(100% - 40px);
      .filter-panel {
        flex-grow: 1;
      }
    }
  }
  .input-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    .label-default {
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
      .wrapper-input {
        padding: 7px 15px;
      }
    }
  }
  .filter-panel {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    > div {
      overflow-x: auto;
      width: 100%;
      height: 100%;
      > div {
        width: 100%;
      }
    }
  }
}
.icon-wrapper-circle {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid $lightgrey;
}
.side-bar-list-icon {
  margin-top: 40px;
  li {
    margin-bottom: 15px;
    &:hover {
      cursor: pointer;
    }
  }
}
.export-blocks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 200px;
  margin-bottom: 25px;
  > div {
    width: 49%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary-light;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    color: $darkgrey;
    flex-direction: column;
    > div {
      margin: 5px;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
      color: $primary;
      svg {
        path {
          fill: $primary;
        }
      }
    }
  }
}

.wrapper-import-box {
  height: 425px;
  border-radius: 8px;
  border: dashed 2px $lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  &.success {
    border: none;
    font-size: 34px;
    + .footer-import .btn-row {
      text-align: center;
      width: 100%;
      margin-bottom: 30px;
    }
    .text {
      font-size: 16px;
      text-align: center;
    }
  }
  &.errors {
    border: none;
  }
  .table-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    .header-row {
      line-height: 50px;
      border-bottom: 1px solid $lightgrey;
    }
    .header-row,
    .item-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 40%;
        text-align: left;
        &:first-of-type {
          max-width: 50px;
        }
        &:last-of-type {
          max-width: 50px;
        }
      }
    }
    .item-row {
      font-size: 16px;
      color: $black;
      padding: 5px 0;
      .circle {
        width: 30px;
        height: 30px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.error {
          background: $green;
        }
        &.success {
          background: $red;
        }
      }
      .error-text {
        color: $red;
      }
      .logo {
        width: 50px;
        height: 50px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: $grey;
      }
      a {
        display: block;
        font-size: 16px;
        color: $primary;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        max-width: 300px;
        white-space: nowrap;
      }
    }
  }
  .table-list {
    height: 100%;
    width: 100%;
    overflow: auto;
  }
  .svg-box {
    text-align: center;
  }
  .error-btn {
    text-align: center;
    color: $primary;
    &:hover {
      cursor: pointer;
    }
  }
  .error-message-modal {
    margin: 20px 0 10px 0;
  }
}
.file-name {
  display: flex;
  width: 100%;
  margin: 10px 0;
  color: $black;
  font-size: 14px;
  line-height: 14px;
  height: 16px;
}
.img-success {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100px;
    height: 110px;
  }
  margin-bottom: 25px;
}
