@import "styles/variables.scss";

.drop-zone-import {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: $black;
  div.title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: $black;
  }
}
