@import "styles/variables.scss";

.config_block {
  display: inline-flex;
  align-items: center;
  ul {
    margin: 0;
    li {
      margin-right: 15px;
      &.border {
        margin: 0 35px 0 20px;
        height: 30px;
      }
      &:last-of-type {
        margin-right: 0;
      }
      button {
        display: flex;
        align-items: center;
        background: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: $black;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}