@import "styles/variables.scss";

.card-image-view-small-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card-item-image-view-small {
  width: 100px;
  height: 100px;
  margin: 0 0 20px;
  position: relative;
  .svg-box {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 10;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $primary-dark;
      opacity: 0.3;
    }
  }
  input[type="checkbox"] {
    display: none;
  }
  input:checked + .card-item-body {
    border: solid 2px $primary;
    .svg-box {
      opacity: 1;
    }
  }
  label {
    display: block;
    margin: 0;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }
  .card-item-body {
    border: solid 2px transparent;
    background-color: $lightgrey;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }
  .card-item-body {
    .img-box {
      position: relative;
      display: flex;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 100%;
        min-height: 100%;
      }
    }
  }
  .footer-card {
    display: block;
    position: absolute;
    left: 5px;
    bottom: 5px;
  }
}
