@import "styles/variables.scss";

.brand-select {
  width: 160px;
  > .front-box {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
  .avatar-box {
    margin-right: 10px;
  }
  &.show {
    .brand-select__box {
      button {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
    .brand-select__drop-down {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      transition-duration: 0.3s;
    }
  }
  &__box {
    display: flex;
    align-items: center;
    button {
      background: none;
    }
  }
  &__description {
    margin-right: 10px;
    span {
      display: block;
      font-size: 12px;
      line-height: 1;
      letter-spacing: normal;
      color: $darkgrey;
      & + div {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $black;
        width: 80px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  &__drop-down {
    position: absolute;
    background: $white;
    z-index: 500;
    left: 0;
    right: 0;
    border-top: solid 1px $lightgrey;
    padding: 45px;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100vh);
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    &:before {
      content: "";
      position: absolute;
      right: 150px;
      top: -8px;
      width: 15px;
      height: 15px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      background: $white;
      border-top: solid 1px $lightgrey;
      border-left: solid 1px $lightgrey;
    }
    .close-btn {
      background: none;
      position: absolute;
      z-index: 10;
      right: 35px;
      top: 35px;
    }
    .search-panel {
      display: flex;
      align-items: center;
      justify-content: center;
      label {
        width: 100%;
        max-width: 600px;
      }
    }
  }
  .filter-box {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        margin: 0 5px;
        button {
          background: none;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          color: $black;
          padding: 5px;
          border-radius: 5px;
          &:hover {
            background: $primary-transparent;
          }
          &.active {
            background: $primary-transparent;
          }
        }
      }
    }
  }
}
.list-brands {
  padding-top: 10px;
  ul {
    padding-top: 10px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
  }
  .brands__item {
    .avatar-box {
      margin: 0;
    }
    width: 15%;
    min-width: 185px;
    height: 185px;
    border-radius: 8px;
    border: solid 1px $lightgrey;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    //margin-bottom: 30px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
      cursor: pointer;
      margin-top: -5px;
      border: none;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.41);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.41);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.41);
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        display: block;
        margin-top: 25px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: $black;
      }
    }
    .select-brand {
      background: none;
      position: absolute;
      right: 5px;
      top: 5px;
      padding: 5px;
    }
  }
}
