.mark-editor-view-detail {
  width: 100%;
  .set-infringing-status-type {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
    .btn-svg-hover {
      width: 47%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .change-label-box .inner-list {
    position: static;
    box-shadow: none;
  }
}
