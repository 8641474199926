@import "styles/variables.scss";

$br: 11px;

.status-icons {
  max-width: 70%;
  &.tooltip-status {
    div.circle-icon {
      padding: 0 5px !important;
    }
  }
  .icon-row {
    display: flex;
    .tooltip-box {
      &:not(&:first-of-type) {
        margin-left: 5px;
      }
    }
    div.circle-icon {
      position: relative;
      width: auto;
      height: 22px;
      min-width: 22px;
      line-height: 20px;
      padding: 0 5px;
      border: 1px solid $lightgrey;
      border-top-left-radius: $br;
      border-bottom-left-radius: $br;
      border-top-right-radius: $br;
      border-bottom-right-radius: $br;
      background: $white;
      font-size: 0;
      margin-right: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        z-index: 6;
        background: $white;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        border-color: inherit;
        border-image: initial;
        width: 10px;
        height: 10px;
        left: 5px;
        top: 5px;
      }
      &:hover {
        cursor: pointer;
        padding: 0 5px 0 20px;
        font-size: 13px;
        & + div {
          font-size: 0;
          line-height: 22px;
          padding: 0 5px;
        }
      }
      &:last-of-type {
        font-size: 13px;
        padding: 0 5px 0 20px;
        margin: 0;
      }
      &.suspicious,
      &.unauthorized {
        &:before {
          border-color: $status-color-1;
        }
      }
      &.unhanded,
      &.no-label {
        &:before {
          border-color: $grey;
        }
      }
      &.genuine,
      &.authorized {
        &:before {
          border-color: $green;
        }
      }
      &.infringement {
        &:before {
          border-color: $red;
        }
      }
      &.irrelevant,
      &.unable-to-determine {
        &:before {
          border-color: $black;
        }
      }
    }
  }
}
