@import "styles/variables.scss";

.customize-body {
  position: relative;
  button{
    background: none;
  }
  > div.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      margin-right: 5px;
    }
  }
  .customize-menu {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 260px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    z-index: 100;
    right: -10px;
    top: 190%;
    margin-top: 30px;
    &.show {
      visibility: visible;
      opacity: 1;
      margin-top: 0;
    }
    &__header {
      padding: 25px 25px 10px 25px;
      border-bottom: 1px solid $lightgrey;
      > .title {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $black;
        margin-bottom: 15px;
      }
      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        label {
          margin: 0;
        }
        button {
          color: $red;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
        }
      }
    }
    &__body {
      ul {
        display: block;
        padding: 10px 10px 10px 20px;
        li {
          display: block;
          margin: 0;
          padding: 10px 5px;
          border-radius: 8px;
          position: relative;
          .icon-dragging {
            position: absolute;
            left: -25px;
            top: 10px;
            display: none;
          }
          label {
            margin: 0;
          }
          &.dragging {
            background: $primary-transparent;
            span.title {
              color: $primary;
            }
            .icon-dragging {
              display: block;
            }
          }
        }
      }
      .list-block-views {
        padding: 25px 15px;
        .title {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: normal;
          color: $black;
          text-align: left;
        }
        ul {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-around;
          padding: 10px 0;
          li {
            margin: 0;
            padding: 0;
            &.active {
              button {
                background: $primary-transparent;
              }
            }
            button {
              display: flex;
              align-items: center;
              justify-content: center;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border-radius: 50%;
              width: 40px;
              height: 40px;
              border: 1px solid $lightgrey;
            }
          }
        }
      }
    }
    &__footer {
      border-top: 1px solid $lightgrey;
      padding: 15px 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      line-height: 1;
      color: $darkgrey;
      ul {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        li {
          margin: 0 8px;
        }
      }
    }
  }
}
