@import "styles/variables.scss";

.card-list-view {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: solid 1px transparent;
  border-bottom: solid 1px $lightgrey;
  &:hover{
    cursor: pointer;
  }
  &.active{
    position: relative;
    z-index: 10;
    background: $primary-light;
    border: solid 1px $lightgrey;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    .title{
      font-weight: bold;
    }
  }
  .check-block{
    width: 24px;
  }
  .image-block{
    width: 80px;
  }
  .price{
    min-width: 60px;
    text-align: right;
  }
  .label-checkbox-default{
    margin: 0;
    input + span{
      margin: 0;
    }
  }
  .description-status{
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    width: 80%;
    width: calc(100% - 164px);
    .title{
      height: 40px;
      margin: 0;
      font-size: 14px;
      line-height: 1.4;
      color: #546369;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
