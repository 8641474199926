@import "styles/variables.scss";

.ModuleCard {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  text-align: center;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid transparent;
  background: $white;
  border: 1px solid $lightgrey;
  text-decoration: none;
  font-size: 16px;
  line-height: 1;
  //max-width:300px;
  min-height: 280px;
  //margin-bottom: 30px;
  //margin: 30px;
  line-height: 24px;
  padding: 20px;
  height: 100%;
  &:hover {
    border: 1px solid $grey;
  }

  >div {
    max-width: 300px;
  }




  .ModuleCard__header {
    font-size : 20px;
    line-height: 28px;
    color : $black;
    margin-bottom:3px;
    font-weight: 500;

      .svg-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 104px;
        margin-bottom: 12px;
        //margin-top: 25px;
    }
  }

  a {
    color: $darkgrey;
    text-decoration: none;
    width: 100%;
  }
}

.dummy, .dummy > div {
    background: none !important;
    border: none !important;
}