@import "styles/variables.scss";

.toggle-view-default {
  display: flex;
  align-items: center;
  .title {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: $darkgrey;
  }
  svg {
    &:hover {
      g > g:last-of-type {
        fill: $primary;
      }
    }
  }
  ul.list-view {
    margin: 0;
    display: flex;
    align-items: center;
    margin-left: 45px;
    > li {
      line-height: 1;
      margin-left: 15px;
      &:first-of-type {
        margin-left: 5px;
      }
    }
  }
  button {
    display: inline-block;
    line-height: 1;
    background: none;
  }
}
