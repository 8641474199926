@import "styles/variables.scss";

.datepicker-main {
  width: 100%;
  border-radius: 4px;
  border: solid 1px #ebf0f4 !important;
  background-color: #ffffff;
  padding: 10px 15px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  .icon-date {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  ::-webkit-input-placeholder {
    color: $black;
  }
  ::-moz-placeholder {
    color: $black;
  } /* Firefox 19+ */
  :-moz-placeholder {
    color: $black;
  } /* Firefox 18- */
  :-ms-input-placeholder {
    color: $black;
  }
  .MuiInputBase-input {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $black;
  }
  .MuiInputBase-root {
    padding: 7px 10px;
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }
}
