@import "styles/variables.scss";

.mixed-view{
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: solid 1px $lightgrey;
  background-color: $white;
  .filter-header{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px $lightgrey;
  }
  .see-more{
    padding: 15px 0;
    text-align: center;
  }
  >div{
    &:first-of-type{
      width: 30%;
      border-right: solid 1px $lightgrey;
    }
    &:nth-child(2){
      width: 70%;
      padding: 25px 20px;
    }
  }
}