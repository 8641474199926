@import "styles/variables.scss";

.message-global {
  position: fixed;
  width: 100%;
  z-index: 1000;
  left: 0;
  top: 0;
  background: $white;
  padding: 18px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ebf0f4;
  font-size: 16px;
  font-weight: 500;
  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .circle {
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: $grey;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.success {
    background-color: #edf6eb;
    .left {
      color: #4cad3a;
      .circle {
        background: #4cad3a;
      }
    }
    .center {
    }
    .right {
    }
  }
}
