@import "styles/variables.scss";

.label-default {
  .wrapper-input {
    textarea {
      width: 100%;
      resize: none;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $black;
    }
  }
}
