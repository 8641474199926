@import "styles/variables.scss";

.filter-customize {
  display: flex;
  padding: 25px 0 15px 0;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 33.33%;
    display: flex;
    align-items: center;
    &:first-of-type {
      justify-content: flex-start;
    }
    &:nth-child(2) {
      justify-content: center;
    }
    &:nth-child(3) {
      justify-content: flex-end;
    }
  }
  &__filter,
  &__counter,
  &__customize {
    display: inline-flex;
    align-items: center;
  }
  &__filter {
    display: flex;
    justify-content: flex-start;
    >div{
      max-width: 50%;
    }
    .dropdown:not(:first-of-type) {
      margin-left: 20px;
    }
  }
  &__counter {
    justify-content: center;
  }
  &__customize {
    justify-content: flex-end;
    > .dropdown {
      margin-right: 20px;
    }
  }
}
