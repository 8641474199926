@import "styles/variables.scss";

.dropdown {
  position: relative;
  .error-message {
    color: $red;
    font-size: 10px;
    position: absolute;
    bottom: -15px;
    left: 0;
  }
  &.error {
    .wrapper-button {
      border-color: $red !important;
      button {
        span {
          color: $red;
        }
        svg {
          g > g > g {
            fill: $red !important;
          }
        }
      }
    }
  }
  input {
    display: none !important;
  }
  .dropdown-body {
    position: relative;
  }
  &.disabled {
    .wrapper-button {
      background-color: #ebf0f4 !important;
      button {
        color: #809096 !important;
      }
      &:hover,
      button {
        cursor: auto !important;
      }
    }
  }
  &.search {
    .dropdown-list {
      &.bottom {
        padding-top: 76px;
        .search-box {
          .search-row {
            position: absolute;
            background: $white;
            left: 0;
            top: 0;
            width: 100%;
          }
        }
      }
      &.top {
        padding-bottom: 76px;
        .search-box {
          .search-row {
            position: absolute;
            background: $white;
            left: 0;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
    .search-row {
      padding: 15px;
      label {
        margin: 0;
      }
      input {
        display: block !important;
      }
      &:hover {
        background: none;
        cursor: auto;
      }
    }
  }
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    &.show {
      display: block;
    }
  }
  .dropdown-list {
    width: 100%;
    background: $white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    ul {
      margin: 0;
      padding: 0;
      li {
        padding: 15px;
        button {
          line-height: 1;
        }
        &.active {
          background: $primary-light;
          color: $primary;
        }
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          background: $primary-transparent;
          color: $primary;
          cursor: pointer;
        }
      }
    }
    &.show {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    }
  }
  .wrapper-button {
    &.show {
      button {
        svg {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
    button {
      display: flex;
      align-items: center;
      >span:not(.svg-icon){
        display: inline-block;
        text-align: left;
        max-width: 180px;
        width: auto;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }
  &.form-dropdown {
    .title {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $black;
      margin-bottom: 5px;
    }
    .wrapper-button {
      border-radius: 4px;
      border: solid 1px $lightgrey;
      background-color: $white;
      padding: 10px 15px;
      &.show {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
      }
      &:hover {
        cursor: pointer;
      }
      .svg-icon.clearable svg{
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
        transition: none;
      }
      button {
        background: none;
        outline: none;
        border: none;
        justify-content: space-between;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: $black;
        width: 100%;
        svg {
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
      }
    }
  }
  &.filter-dropdown {
    display: flex;
    align-items: center;
    .title {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: right;
      color: $darkgrey;
      margin-right: 0px;
    }
    .wrapper-button {
      button {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: $black;
        background: none;
        padding-left: 10px;
        position: relative;
        height: 24px;
        padding-right: 24px;
        justify-content: flex-end;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.5;
          width: inherit !important;
        }
        svg {
          margin-left: 10px;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
        }
      }
    }
    .dropdown-list {
      width: auto;
      min-width: 100%;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      left: inherit;
      right: 0;
      ul {
        padding: 5px 0;
        overflow: auto;
      }
      ul li {
        text-align: left;
        white-space: nowrap;
        &.active {
          background: $primary-transparent;
        }
        &:hover {
          background: $primary-transparent;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
