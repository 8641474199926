@import "styles/variables.scss";

.moduleEntry {
    > a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        width: 100%;
        height: 100%;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        border: 1px solid transparent;
        text-decoration: none;
        font-size: 22px;
        font-weight: 500;
        line-height: 1;
        color: $black;
        .svg-icon {
          margin-left: 8px;
          width: 104px;
          height: 104px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &:hover {
          border: 1px solid $grey;
        }
      }
}