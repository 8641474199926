@import "styles/variables.scss";

.select-box {
  margin-left: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: $darkgrey;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  > span:first-of-type {
    &:hover {
      cursor: pointer;
    }
  }
  span.border {
    margin: 0 15px;
    height: 30px;
  }
}

