@import "styles/variables.scss";

.list-info {
  display: block;
  margin: 0;
  li {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid $lightgrey;
    span {
      color: $black;
    }
    .list-title {
      color: $darkgrey;
    }
    img {
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
  }
}