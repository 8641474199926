@import "styles/variables.scss";

.card-item-image-view {
  position: relative;
  height: 415px;
  margin-bottom: 30px;
  &.small {
    height: 195px;
    .footer-card .description {
      padding: 0 5px;
    }
    .card-item-footer {
      padding: 0 5px;
    }
    .comments-counter {
      opacity: 0;
    }
    &:hover {
      .comments-counter {
        opacity: 1;
      }
    }
  }
  &:before {
    visibility: hidden;
    opacity: 0;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $primary-dark;
    z-index: 10;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 8px;
  }
  &.checked {
    &:before {
      visibility: visible;
      opacity: 0.3;
    }
    .card-item-header {
      opacity: 1;
      visibility: visible;
    }
  }
  &:hover {
    cursor: pointer;
    &:before {
      visibility: visible;
      opacity: 0.3;
    }
    .card-item-header {
      opacity: 1;
      visibility: visible;
    }
    .title-description-card {
      margin-left: 0 !important;
      opacity: 1 !important;
      visibility: visible !important;
    }
    .footer-card {
      .card-item-footer {
        margin-bottom: 0;
        visibility: visible;
        opacity: 1;
        a {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .card-item-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 150;
    opacity: 0;
    visibility: hidden;
    .title {
      color: $white;
    }
  }
  .card-item-body {
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    background: $lightgrey;
    .img-box {
      height: 100%;
    }
  }
  .card-item-footer {
    background-color: $primary-transparent;
    color: $primary;
    display: flex !important;
    a {
      visibility: hidden;
      opacity: 0;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
  }
  .footer-card {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    z-index: 15;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    .card-item-footer {
      visibility: hidden;
      opacity: 0;
      margin-bottom: -55px;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      overflow: hidden;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .description {
      padding: 0 15px;
      .title-box {
        overflow: hidden;
      }
      .title-description-card {
        opacity: 0;
        visibility: hidden;
        margin-left: -100%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        span {
          color: $lightgrey;
          margin-bottom: 10px;
          font-size: 12px;
        }
        div {
          font-size: 24px;
          font-weight: 500;
          line-height: 1;
          color: $lightgrey;
          margin-bottom: 15px;
        }
      }
      .detail {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .views {
          display: flex;
          align-items: center;
          margin-left: 0 !important;
          svg {
            margin-right: 5px;
          }
        }
        > div {
          display: flex;
          align-items: center;
        }
        .status-icons {
          margin-left: 6px;
        }
        .comments-counter,
        .views {
          margin-left: 15px;
          height: 24px;
          border-radius: 4px;
          background: $white;
          padding: 0 5px;
        }
      }
    }
  }
}
