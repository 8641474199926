@import "styles/variables.scss";

.change-label-box {
  position: relative;
  .inner-list {
    &.top-bar {
      position: absolute;
      top: 50px;
      z-index: 100;
      left: -260px;
      width: 420px;
      border-radius: 8px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
      background-color: $white;
      padding: 15px 10px;
    }
    .children {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    span {
      display: block;
      font-size: 14px;
      margin-bottom: 10px;
      color: $black;
    }
    ul {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        width: 49%;
        padding: 5px 15px;
        margin-bottom: 5px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        border: 1px solid $lightgrey;
        color: $darkgrey;
        display: flex;
        align-items: center;
        &.active {
          color: #4cad3a;
          background: #edf7eb;
          border-color: #edf7eb;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
