@import "styles/variables.scss";

.images-row-preview{
  .view-panel-slider{
    height: 350px;
  }
}
.images-row-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 15px;
  &.hover-active{
    >div{
      &:hover{
        cursor: pointer;
      }
    }
  }
  >div{
    height: 85px;
    width: 85px;
    border-radius: 8px;
    margin-bottom: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    &.image-length{
      border: 1px solid $lightgrey;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      text-align: center;
      color: #546369;
      &:hover{
        background: $lightgrey;
        cursor: pointer;
      }
    }
    img{
      max-width: 100%;
      max-height: 100%;
    }
    &.active{
      border: 1px solid #ebf0f4;
    }
  }
}
